'use client';

import { useDispatch, useSelector } from 'react-redux';
import { closeLoginModal } from "@/store/reducers/loginModal";
import Snow from "@/components/hero/snow/Snow";
import AuthModal from "@/components/auth/AuthModal";
import { Toaster } from "@/components/ui/toaster";
import { useEffect } from 'react';

interface RootState {
  loginModal: boolean;
}

export default function LayoutContent({ children }: { children: React.ReactNode }) {
  const loginModal = useSelector((state: RootState) => state.loginModal);
  const dispatch = useDispatch();

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
      .register('/service-worker.js', { scope: '/' }).catch((error) => console.error('Service worker registration failed:', error));
    }
  }, []);

  function closeModal(): void {
    dispatch(closeLoginModal());
  }

  return (
    <>
      {/* <div className="top-0 left-0 z-50 fixed w-screen h-full pointer-events-none">
        <Snow />
      </div> */}

      {children}
      <Toaster />
      <AuthModal isOpen={loginModal} onOpenChange={closeModal} activeTab="login" />
    </>
  );
}
