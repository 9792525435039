"use client";
import React, { useEffect, useRef, useState } from "react";
import { ScrollTrigger } from "gsap/all";
import option1 from "@/public/about/about-option-image-1-small.png";
import option1_preview from "@/public/about/about-option-image-1.png";
import option2 from "@/public/about/about-option-image-2-small.png";
import option2_preview from "@/public/about/about-option-image-2.png";
import option3 from "@/public/about/about-option-image-3-small.png";
import option3_preview from "@/public/about/about-option-image-3.png";
import about_offers from "@/public/about/about-offers-image.png"
import about_offers_overlay from "@/public/about/about-offers-image-overlay.png"
import about_offers_overlay_bottom from "@/public/about/about-bento-image-overlay-bottom.png"
import imageOverlay from "@/public/about/about-bento-image-overlay.png";
import OptionButton from "@/components/common/about/OptionButton";
import AboutButton from "../common/about/AboutButton";
import Image from "next/image";
import DealsList from "../weekend-deals/DealsList";
import '@/styles/about.css'
import { DealsProps } from "@/common/interfaces";
import { fetchAboutData } from "@/service/about";
import AuthModal from "../auth/AuthModal";


const AboutPage = () => {
  const [activeOption, setActiveOption] = useState(option1_preview);

  const handleOptionClick = (option: any) => {
    setActiveOption(option);
  };
  const AboutContainerRef = useRef<HTMLDivElement | null>(null);
  const topDivRef = useRef<HTMLDivElement | null>(null);
  const topRightDivRef = useRef<(HTMLDivElement | null)[]>([]);
  const slideDownDelayedRef = useRef<(HTMLDivElement | SVGImageElement | null)[]>([]);
  const slideDowUPDelayedRef = useRef<(HTMLDivElement | SVGImageElement | null)[]>([]);
  const [deals, setDeals] = useState<DealsProps[]>([]);
  const opacityUpDelayedRef = useRef<(HTMLDivElement | null)[]>([]);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<"login" | "register">("login");

  const animationsTriggered = useRef(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting && !animationsTriggered.current) {
          animationsTriggered.current = true;
          topRightDivRef.current.forEach((div) => {
            if (entry.isIntersecting) {
              div?.classList.add('animatedSlideDown');
            } else {
              div?.classList.remove('animatedSlideDown');
            }
          });
          slideDownDelayedRef.current.forEach((div) => {
            if (entry.isIntersecting) {
              div?.classList.add('animatedSlideDownDelayed');
            } else {
              div?.classList.remove('animatedSlideDownDelayed');
            }
          });
          slideDowUPDelayedRef.current.forEach((div) => {
            if (entry.isIntersecting) {
              div?.classList.add('animatedSlideUpDelayed');
            } else {
              div?.classList.remove('animatedSlideUpDelayed');
            }
          });
          opacityUpDelayedRef.current.forEach((div) => {
            if (entry.isIntersecting) {
              div?.classList.add('animatedOpacityUpDelayed');
            } else {
              div?.classList.remove('animatedOpacityUpDelayed');
            }
          });


          if (topDivRef.current) {
            if (entry.isIntersecting) {
              topDivRef.current.classList.add('animatedSlideUp');
            } else {
              topDivRef.current.classList.remove('animatedSlideUp');
            }
          }

        }
      },
      {
        root: null,
        threshold: 0.1,
      }
    );

    if (AboutContainerRef.current) {
      observer.observe(AboutContainerRef.current);
    }

    return () => {
      if (AboutContainerRef.current) {
        observer.unobserve(AboutContainerRef.current);
      }
    };
  }, []);

  useEffect(() => {
    
    fetchData();
  }, []);

  const fetchData = async () => {
    await fetchAboutData().then((response) => {


      setDeals(response)


      setTimeout(() => {
        ScrollTrigger.refresh();
      }, 100);
    });
  }

  const handleSignUpModal = () => {

    setIsModalOpen(true);
    setActiveTab("register");

  }

  const gotoToHost = () => {
    const hostUrl = process.env.HOST_URL || 'https://apartment-host.webmotech.com';
    window.open(hostUrl, '_blank');
  };


  return (
    <div className="max-[1440px]:px-10 max-[435px]:px-3 max-[672px]:px-5 p-20 h-max about-page" ref={AboutContainerRef}>
      <div className="relative flex flex-row max-[935px]:flex-col justify-between items-start max-[935px]:items-center max-[935px]:gap-10 space-x-10 max-[1398px]:space-x-3 h-max">

        <div className="relative" ref={topDivRef}>
          <div className="relative">
            <svg
              viewBox="0 0 681 527"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="about-main-svg"
            >
              <defs>
                <pattern
                  id="bgPattern"
                  patternUnits="objectBoundingBox"
                  width="1"
                  height="1"
                >
                  <image
                    href={activeOption.src}
                    x="0"
                    y="0"
                    width="681"
                    height="527"
                    preserveAspectRatio="xMidYMid slice"
                    className="transition-opacity duration-500 ease-in-out"
                  />

                  <image
                    href={imageOverlay.src}
                    x="50"
                    y="25"
                    preserveAspectRatio="xMidYMid slice"
                    className="opacity-0"
                    ref={(el) => { slideDownDelayedRef.current[1] = el }}
                  />
                  <image
                    href={about_offers_overlay_bottom.src}
                    x="220"
                    y="400"
                    preserveAspectRatio="xMidYMid slice"
                    className="opacity-0"
                    ref={(el) => { slideDowUPDelayedRef.current[0] = el }}
                  />
                </pattern>
              </defs>
              <path
                d="M1 510.5V482L1.00018 28C1.00018 9 2.50018 1 26.5002 1H652C673.2 1 679.5 20.3333 680 30V417C680 428.6 670.667 431.833 666 432H491C475 432 471 442.667 471 448L471 506C471 519.5 468 525 456 525L112 526C85.8334 525.667 30.4 525 18 525C5.6 525 1 520 1 510.5Z"
                fill="url(#bgPattern)"
              />
            </svg>
          </div>

          <div className="right-0 max-[1398px]:right-1 bottom-1 max-[1398px]:bottom-0 z-10 absolute flex space-x-[-20px]">
            <OptionButton
              active={activeOption === option1_preview}
              option={option1}
              onClick={() => handleOptionClick(option1_preview)}
            />
            <OptionButton
              active={activeOption === option2_preview}
              option={option2}
              onClick={() => handleOptionClick(option2_preview)}
            />
            <OptionButton
              active={activeOption === option3_preview}
              option={option3}
              onClick={() => handleOptionClick(option3_preview)}
            />
          </div>
        </div>

        <div className="flex flex-col justify-center items-center">
          <div className="relative flex flex-1 justify-center items-center">
            <div className="flex max-[672px]:flex-col justify-between items-start gap-10 max-[1398px]:gap-3 max-[672px]:gap-10 w-full h-max">

              <div className="flex flex-col justify-between items-center gap-5 border-2 border-primary bg-primary bg-opacity-25 p-6 border-opacity-5 rounded-3xl w-full h-max" ref={(el) => { topRightDivRef.current[0] = el }}>
                <h1 className="pr-28 max-[1398px]:pr-10 max-[1500px]:pr-16 font-normal text-5xl max-[1398px]:text-4xl max-[1072px]:text-3xl max-[1618px]:text-[40px] max-[935px]:text-5xl max-[798px]:text-4xl">A lot can happen with little space.</h1>
                <p className="description-paragraph-default font-normal font-poppins text-lg text-muted">Explore our uniquely deigned spaces that offer privacy, comfort,and a taste of SriLanka's rich culture.</p>
                <AboutButton label='Host your property' type={'default'} onClick={gotoToHost} />
              </div>

              <div className="flex flex-col justify-between items-center gap-5 border-2 border-secondary bg-secondary bg-opacity-25 p-6 border-opacity-5 rounded-3xl w-full h-max" ref={(el) => { topRightDivRef.current[1] = el }}>
                <div
                  className="relative bg-cover bg-no-repeat bg-center border rounded-2xl w-full h-60"
                  style={{
                    backgroundImage: `url(${about_offers.src})`,
                  }}
                >
                  <div className="top-0 left-0 absolute w-full h-full">
                    <Image loading="lazy" src={about_offers_overlay} alt='Serviced apartments offers' className="top-4 right-4 absolute opacity-0" ref={(el) => { slideDownDelayedRef.current[0] = el }} />
                  </div>

                </div>

                <p className="description-paragraph-default font-normal font-poppins text-center text-lg text-primary max">Offer for your first 2 bookings in Serviced apartments</p>
                <AboutButton label='Sign up Now' type={'danger'} onClick={handleSignUpModal} />
              </div>
            </div>
          </div>


          <div>
            <p className="opacity-0 mt-5 description-paragraph-default font-normal font-poppins text-lg text-muted" ref={(el) => { opacityUpDelayedRef.current[0] = el }}>
              Explore our uniquely designed spaces that offer privacy, comfort, and a taste of Sri Lanka’s rich culture. Explore our uniquely designed spaces that offer privacy, comfort.
            </p>
          </div>
        </div>

      </div>

      <div className={`flex-col justify-between items-start w-full h-max ${deals.length === 0 ? 'hidden' : 'flex'}`}>
        <div className="relative pt-20">
          <h1 className="opacity-0 title" ref={(el) => { slideDowUPDelayedRef.current[1] = el }}>Most Booked Stays</h1>
          <h3 className="opacity-0 subtitle" ref={(el) => { slideDowUPDelayedRef.current[2] = el }}>Top Stays Booked in the Past Month</h3>
        </div>

        <DealsList dealsList={deals} />
      </div>

      <AuthModal isOpen={isModalOpen} onOpenChange={setIsModalOpen} activeTab={activeTab} />
    </div>
  );
};

export default AboutPage;