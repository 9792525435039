"use client"

import React, { useEffect, useState } from 'react'
import { Button } from '@/components/ui/button';
import { addDays, format } from "date-fns"
import { CalendarIcon } from "lucide-react"
import { cn } from "@/lib/utils"
import { Calendar } from "@/components/ui/calendar"
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover"
import { DateRange } from 'react-day-picker';

const SearchByDate = ({ onDateChangeDate }: { onDateChangeDate: (date: DateRange | undefined) => void }) => {
    const [date, setDate] = useState<DateRange | undefined>({
        from: new Date(),
        to: addDays(new Date(), 1),
    });

    useEffect(() => {
        onDateChangeDate(date)
    }, [date])

    return (
        <Popover>
            <PopoverTrigger asChild>
                <Button
                    id="date"
                    variant={"outline"}
                    className={cn(
                        "justify-start hover:bg-transparent hover:scale-[1.02] duration-100 transition-transform text-left font-normal max-[1315px]:text-sm border-0 text-base w-[380px] max-[1095px]:w-[270px] max-[1267px]:w-[290px] max-[1315px]:w-[320px] py-6 bg-transparent border-r rounded-none border-[#807e7e]",
                        !date && "text-muted-foreground"
                    )}
                >
                    <CalendarIcon className='search-icons' />
                    {date?.from ? (
                        date.to ? (
                            <>
                                {format(date.from, "LLL dd, y")} -{" "}
                                {format(date.to, "LLL dd, y")}
                            </>
                        ) : (
                            format(date.from, "LLL dd, y")
                        )
                    ) : (
                        <span>Pick a date</span>
                    )}
                </Button>
            </PopoverTrigger>
            <PopoverContent className="p-0 w-auto" align="start">
                <Calendar
                    initialFocus
                    mode="range"
                    defaultMonth={date?.from}
                    selected={date}
                    onSelect={setDate}
                    numberOfMonths={2}
                    className="custom-calendar"
                />
            </PopoverContent>
        </Popover>
    )
}

export default SearchByDate