'use client'
import React, { useEffect, useState } from 'react'
import SearchContainer from '../serachContainer/SearchContainer'
import Navigation from '@/components/navigation/Navigation'
import UnifiedParallaxCanvas from './UnifiedParallaxCanvas'
import Image from 'next/image'
import tower from '@/public/hero-images/lotus-tower/lotusTowerBgMobile.webp'



const ImageContainer: React.FC<{ aboutRef?: React.RefObject<HTMLDivElement>, footerRef?: React.RefObject<HTMLDivElement> }> = ({ aboutRef, footerRef }) => {


    return (
        <>
            <div className="max-[769px]:relative top-0 sticky w-full h-screen overflow-hidden">
                <div className="relative z-[-1] w-full h-screen parallax-main">
                    <div className="relative w-full h-full overflow-hidden">
                        <div className='max-[536px]:flex hidden w-screen h-screen'>
                            <Image src={tower} priority alt='Lotus Tower'/>
                        </div>
                        <div className='min-[537px]:flex hidden'>
                            <UnifiedParallaxCanvas />
                            
                        </div>
                    </div>
                    <div className="absolute inset-0 bg-gradient-to-t from-[#0B161F] via-[transparent] to-transparent opacity-100"></div>
                </div>
                <SearchContainer />
                <div className='bottom-10 z-20 fixed min-[770px]:flex flex-col justify-center items-center hidden w-full'>
                    <Navigation aboutRef={aboutRef} footerRef={footerRef} />
                </div>
            </div>
        </>

    )
}

export default ImageContainer