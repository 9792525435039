import Image, { StaticImageData } from 'next/image';
import React from 'react';

const LocationCard = ({ name, href, alt, onClick, isSelected }: { name: string, href: StaticImageData, alt: string, onClick: () => void, isSelected: boolean }) => {
    return (
        <div
            className={`relative max-[1440px]:w-[300px] max-[1440px]:h-[130px] max-[1440px]:m-1 shadow-md hover:shadow-lg m-2 rounded-xl w-[300px] h-[200px] transition-shadow duration-300 overflow-hidden ${isSelected ? 'border-secondary border-2' : ''}`}
            onClick={onClick}
        >
            <h1 className="top-2 right-2 z-10 absolute border-white bg-white bg-opacity-10 shadow-md backdrop-blur-md px-3 py-1 border rounded-lg font-poppins text-base text-white tracking-wide">
                {name}
            </h1>

            <div className="relative w-full h-full">
                <Image
                    loading="lazy"
                    src={href}
                    alt={alt}
                    fill
                    style={{ objectFit: "cover" }}
                    className="transition-transform duration-300 ease-out hover:scale-105"
                />
            </div>

            <div className="absolute inset-0 flex items-end bg-gradient-to-t from-primary via-transparent to-transparent opacity-0 hover:opacity-100 transition-opacity duration-300">
                <div className="p-4 text-white">
                    <p className="font-poppins text-sm pointer-events-none">{alt}</p>
                </div>
            </div>
        </div>
    );
};

export default LocationCard;
