import React, { useEffect, useState } from 'react'
import {
    Avatar,
    AvatarFallback,
    AvatarImage,
} from "@/components/ui/avatar"
import {
    HoverCard,
    HoverCardContent,
    HoverCardTrigger,
} from "@/components/ui/hover-card"
import {
    Menubar,
    MenubarContent,
    MenubarItem,
    MenubarMenu,
    MenubarTrigger,
} from "@/components/ui/menubar"
import { logout } from "@/actions/services/logout";
import { LogOut } from 'lucide-react';
import { getLoggedUser } from '@/common/commonFunctions';
import defaultUser from "@/public/shared/defaultUser.png"

const UserAvatar = () => {
    const [user, setUser] = useState<any>(null);

    const handleLogout = async () => {
        const response = await logout();

        if (response.success) {
            window.location.reload();
        } else {
            console.error(response.error);
        }
    };

    useEffect(() => {
        getUser();
    }, [])

    


    const getUser = async () => {
        const response = await getLoggedUser();
        if (response) {
            setUser(response);
        }
    }
    return (
        <Menubar className="bg-transparent focus:!bg-transparent shadow-none border-none w-min">
            <MenubarMenu>
                <MenubarTrigger className="bg-transparent w-min">
                    <HoverCard>
                        <HoverCardTrigger asChild className="bg-transparent cursor-pointer">
                            <Avatar>
                                <AvatarImage src={`${user?.file?.smallPath || defaultUser.src}`} alt="User Image" />
                            </Avatar>
                        </HoverCardTrigger>
                        {/* <HoverCardContent className="right-0 absolute w-80 font-poppins">
                            <div className="flex justify-between space-x-4">
                                <Avatar>
                                    <AvatarImage src="https://github.com/shadcn.png" />
                                    <AvatarFallback>SA</AvatarFallback>
                                </Avatar>
                                <div className="space-y-1">
                                    <h4 className="font-semibold text-left text-sm">@example_name</h4>
                                    <p className="text-left text-sm">
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                                    </p>
                                    <div className="flex items-center pt-2">
                                        <Mail className="opacity-70 mr-2 w-4 h-4" />{" "}
                                        <span className="text-muted-foreground text-xs">
                                            customer@example.com
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </HoverCardContent> */}
                    </HoverCard>
                </MenubarTrigger>
                <MenubarContent className="-right-10 absolute font-poppins">
                    <MenubarItem className="px-5 cursor-pointer" onClick={handleLogout}>
                        <div className="flex items-center">
                            <LogOut className="opacity-70 mr-2 w-4 h-4" />{" "}
                            <span className="text-muted-foreground text-xs">
                                Logout
                            </span>
                        </div>
                    </MenubarItem>
                </MenubarContent>
            </MenubarMenu>
        </Menubar>

    )
}

export default UserAvatar