"use client"
import React from 'react'
import { ReactLenis } from '@/components/common/SmoothScroll'
import Navbar from '@/components/navigation/Navbar'

const MainLayout = ({ children }: { children: React.ReactNode }) => {
    return (
        <>
            <header >
                <Navbar />
            </header>
            <ReactLenis root>
                {children}
            </ReactLenis>

        </>
    )
}

export default MainLayout