interface ModalState {
    loginModal: boolean;
}

type ModalAction =
    | { type: 'OPEN_LOGIN_MODAL' }
    | { type: 'CLOSE_LOGIN_MODAL' };


const initialState: ModalState = { loginModal: false };


const actionTypes = {
    OPEN_LOGIN_MODAL: 'OPEN_LOGIN_MODAL',
    CLOSE_LOGIN_MODAL: 'CLOSE_LOGIN_MODAL',
} as const;


export const openLoginModal = (): ModalAction => ({
    type: actionTypes.OPEN_LOGIN_MODAL
});

export const closeLoginModal = (): ModalAction => ({
    type: actionTypes.CLOSE_LOGIN_MODAL
});


const reducer = (state = initialState, action: ModalAction): ModalState => {
    switch (action.type) {
        case actionTypes.OPEN_LOGIN_MODAL:
            return { ...state, loginModal: true };
        case actionTypes.CLOSE_LOGIN_MODAL:
            return { ...state, loginModal: false };
        default:
            return state;
    }
}

export default reducer;