'use client'

import "./globals.css";
import "./animations.css"
import localFont from "next/font/local";
import { Provider } from 'react-redux';
import store from '@/store/store';
import LayoutContent from "./LayoutContent";

const iBrand = localFont({
  src: [
    {
      path: '../public/fonts/Ibrand.ttf',
      style: 'normal'
    }
  ],
  variable: '--font-ibrand'
});



export default function RootLayout({
  children,
}: Readonly<{
  children: React.ReactNode;
}>) {
  return (
    <html lang="en">
      <head>

        <link href="https://fonts.googleapis.com/icon?family=Material+Icons" rel="stylesheet" />


        <meta httpEquiv="Cross-Origin-Opener-Policy" content="same-origin-allow-popups" />
        <meta httpEquiv="Cross-Origin-Embedder-Policy" content="require-corp" />



        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </head>

      <body className={`${iBrand.className}`}>
        <Provider store={store}>
          <LayoutContent>
            {children}
          </LayoutContent>
        </Provider>
      </body>
    </html>
  );
}


{/* <meta property="og:title" content="Best Property Selling & Hotel Booking Website in Sri Lanka" />
        <meta property="og:description" content="Looking for serviced apartments, hotel bookings, or properties for sale in Sri Lanka? Discover the best website for luxury apartments, and rentals." />
        <meta property="og:image" content="https://apartment-web.webmotech.com/logo-sm.png" />
        <meta property="og:image:alt" content="Best Property Selling and Hotel Booking Website in Sri Lanka" />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:site_name" content="Serviced Apartments Sri Lanka" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://apartment-web.webmotech.com/" />

        
        <meta name="twitter:title" content="Best Property Selling & Hotel Booking Website in Sri Lanka" />
        <meta name="twitter:description" content="Explore serviced apartments, properties for sale, and the best hotel booking options in Sri Lanka. Perfect for living, renting, or investing." />
        <meta name="twitter:image" content="https://apartment-web.webmotech.com/logo-sm.png" />
        <meta name="twitter:card" content="summary_large_image" />

       
        <meta name="description" content="The best website for hotel bookings, serviced apartments, and properties for sale in Sri Lanka. Find luxury apartments, real estate opportunities, and more." />
        <meta name="keywords" content="best hotel booking website Sri Lanka, best property selling website Sri Lanka, serviced apartments Sri Lanka, apartments for sale Sri Lanka, real estate Sri Lanka, property for rent Sri Lanka" />
        <meta name="author" content="Serviced Apartments Sri Lanka" />

        
        <meta name="robots" content="index, follow" /> */}